<template>
  <div class="role-list">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">

      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增角色</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA', color: '#000000'
    }">
      <el-table-column label="商户角色名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.roleName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)" v-has="'merchant_role_edit'">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)" v-has="'merchant_role_del'">删除</el-button>
          <el-button type="text" size="medium" @click.stop="onAuto(scope.row)"
            v-has="'merchant_role_menu'">菜单授权</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="editShow" :title="editTitle" :close-on-click-modal="false" width="30%"
      custom-class="add-dialog">
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 角色名称 -->
        <el-form-item label="角色名称" label-width="120px" prop="roleName">
          <el-input v-model.trim="params.roleName" autocomplete="off" placeholder="请填写角色名称" maxlength="10"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
    <!-- 授权弹框 -->
    <el-dialog :visible.sync="autoShow" :title="autoTitle" :close-on-click-modal="false" width="40%">
      <section class="tree-box">
        <div class="tree-item">
          <h1 class="tree-title">门店管理系统：</h1>
          <p class="check-all">
            <el-checkbox :indeterminate="pcCheckItem" v-model="pcCheckAll" @change="onCheckPcAll">全选</el-checkbox>
          </p>
          <el-tree :data="menuListPc" ref="treePc" :check-strictly="checkStrictly" show-checkbox node-key="funId"
            :default-expand-alls="true" :highlight-current="true" :check-on-click-node="true" :props="defaultProps"
            @check-change="onCheckPcItem">
          </el-tree>
        </div>
        <div class="tree-item">
          <h1 class="tree-title">壹启智享商户端：</h1>
          <p class="check-all">
            <el-checkbox :indeterminate="appCheckItem" v-model="appCheckAll" @change="onCheckAppAll">全选</el-checkbox>
          </p>
          <el-tree :data="menuListApp" ref="treeApp" :check-strictly="checkStrictly" show-checkbox node-key="funId"
            :default-expand-alls="true" :highlight-current="true" :check-on-click-node="true" :props="defaultProps"
            @check-change="onCheckAppItem">
          </el-tree>
        </div>
      </section>
      <div slot="footer" class="dialog-footer">
        <el-button @click="autoShow = false">取 消</el-button>
        <el-button type="primary" @click="onAutoSubmit()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getRoleEdit,
  getRoleDel,
  getRoleMenu,
  getRoleAuto
} from "@/api/setting/role"
import {
  getRoleList
} from "@/api/merchant/shopRole"
import {
  getAllMenu,
} from "@/api/setting/menu";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      rules,
      // 列表
      currentPage: 1, // 当前页
      total: 0, // 总条数  
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        merchantId: ""
      },
      // 表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      params: {
        roleId: "", //角色ID
        roleName: "", //角色名称
        merchantId: "",//商户
      },
      // 授权
      autoRoleId: "",
      autoTitle: "菜单授权",
      autoShow: false,
      menuListPc: [],
      menuListApp: [],
      defaultProps: {
        label: 'functionName',
        children: 'functionVos',
      },
      checkStrictly: true,
      // 全选
      pcCheckAll: false,
      pcCheckItem: false,
      appCheckAll: false,
      appCheckItem: false,
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.params.merchantId = JSON.parse(merchantShopId)[0];
    }
  },
  mounted() {
    this.getRoleList();
    this.getAllMenu();
  },
  methods: {
    // 【请求】角色列表
    getRoleList() {
      let data = this.tableParams;
      getRoleList(data).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },

    // 【请求】新增角色
    getRoleEdit() {
      let data = this.params;
      getRoleEdit(data).then(res => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          if (data.roleId) {
            this.getRoleList();
          } else {
            this.reload()
          }
        }
      })
    },

    // 【请求】全部菜单列表
    getAllMenu() {
      let data = {
        merchantId: this.params.merchantId, //角色名称
      };
      getAllMenu(data).then(res => {
        this.menuListPc = res.data.pc;
        this.menuListApp = res.data.app;
      })
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getRoleList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getRoleList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】新增用户
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增角色";
      this.params = {
        roleId: "", //用户ID
        roleName: "", //角色名称
        merchantId: this.params.merchantId, //角色名称
      }
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑按钮
    onEdit(row) {
      console.log('row: ', row);
      this.editShow = true;
      this.editTitle = "编辑角色";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听删除】
    onDel(row) {
      let data = {
        roleId: row.roleId
      }
      this.$confirm('你确定要删除该角色吗?', '删除角色', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      }).then(() => {
        getRoleDel(data).then(res => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getRoleList();
          }
        })
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getRoleEdit();
        } else {
          return false;
        }
      });
    },

    // 【监听】菜单授权回显
    onAuto(row) {
      this.autoShow = true;
      this.autoRoleId = row.roleId
      this.autoTitle = ` ${row.roleName}`
      let data = {
        roleId: row.roleId,
      }
      getRoleMenu(data).then(res => {
        this.$nextTick(() => {
          this.$refs.treePc.setCheckedNodes(res.data.roleFunctions);
          this.$refs.treeApp.setCheckedNodes(res.data.roleFunctions);
          this.onCheckPcItem();
          this.onCheckAppItem();
        })
      })
    },

    // 【监听】菜单授权保存
    onAutoSubmit() {
      let pc = this.$refs.treePc.getCheckedNodes(false, true);
      let app = this.$refs.treeApp.getCheckedNodes(false, true);
      let data = {
        roleId: this.autoRoleId,
        roleFunctions: [...pc, ...app]
      }
      getRoleAuto(data).then(res => {
        if (res.isSuccess == "yes") {
          this.$message({
            type: 'success',
            message: res.message
          });
          this.autoShow = false;
        }
      })
    },

    // 【转换】pc端菜单项
    changeMenu(list) {
      let arr = JSON.parse(JSON.stringify(list));
      for (let i of arr) {
        if (i.functionVos) {
          arr = [...arr, ...i.functionVos];
          for (let j of i.functionVos) {
            if (j.functionVos) {
              arr = [...arr, ...j.functionVos];
            }
          }
        }
      }
      return arr
    },

    // 【监听】pc全选
    onCheckPcAll(flag) {
      flag ? this.$refs.treePc.setCheckedNodes(this.changeMenu(this.menuListPc)) : this.$refs.treePc.setCheckedNodes([])
      this.pcCheckItem = false;
    },

    // 【监听】pc单选
    onCheckPcItem() {
      let pc = this.$refs.treePc.getCheckedNodes(false, true);
      let pcAll = this.changeMenu(this.menuListPc);
      this.pcCheckAll = pc.length == pcAll.length;
      this.pcCheckItem = pc.length > 0 && pc.length < pcAll.length;
    },

    // 【监听】小程序全选
    onCheckAppAll(flag) {
      flag ? this.$refs.treeApp.setCheckedNodes(this.changeMenu(this.menuListApp)) : this.$refs.treeApp.setCheckedNodes([])
      this.appCheckItem = false;
    },

    // 【监听】小程序单选
    onCheckAppItem() {
      let app = this.$refs.treeApp.getCheckedNodes(false, true);
      let appAll = this.changeMenu(this.menuListApp);
      this.appCheckAll = app.length == appAll.length;
      this.appCheckItem = app.length > 0 && app.length < appAll.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/theme';

.role-list {
  padding: .15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: .15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: .1rem;
  display: flex;
  justify-content: flex-end;
}

.tree-box {
  display: flex;
  justify-content: space-around;
}

.tree-title {
  min-width: 2rem;
  padding: .1rem .2rem;
  height: .28rem;
  background: #eee;
  font-size: .16rem;
}

.tree-item {
  border: 1px solid #eee;
}

.el-tree {
  padding: .1rem;
}

.check-all {
  padding: .1rem .1rem 0 .1rem;
}
</style>
