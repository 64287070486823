import request from '@/utils/request'

// 获取当前权限菜单
export function getMenulist(data) {
  return request('post', '/apa/menu/getUserMenus', data)
}

// 获取全部菜单
export function getAllMenu(data) {
  return request('post', '/apa/function/roleBindingFun', data)
}

// 新增菜单
export function getAddMenu(data) {
  return request('post', '/apa/function/create', data)
}

// 编辑菜单
export function getEditMenu(data) {
  return request('post', '/apa/function/update', data)
}

// 删除菜单
export function getDelMenu(data) {
  return request('post', '/apa/function/del', data)
}