import request from '@/utils/request'

// 角色列表
export function getRoleList(data) {
  return request('post', '/apa/role/findRoleList', data)
}

// 新增和编辑角色
export function getRoleEdit(data) {
  return request('post', '/apa/role/mod', data)
}

// 删除角色
export function getRoleDel(data) {
  return request('post', '/apa/role/del', data)
}

// 角色权限菜单
export function getRoleMenu(data) {
  return request('post', '/apa/role/findRole', data)
}

// 角色授权
export function getRoleAuto(data) {
  return request('post', '/apa/roleFunction/mod', data)
}
